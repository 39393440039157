import * as XLSX from "xlsx";
import React, { useEffect, useMemo, useState } from "react";
import ReactApexChart from "react-apexcharts";
import { parse, getMonth, getISOWeek, format } from "date-fns";
import { useJobs } from "../../hooks/useJobs/useJobs";
import Button from "../Button/Button";
import HeatmapChart from "./HeatmapChart";
import SpeakersChart from "./SpeakersChart";
import Header from "../Header/Header";
import FilterSelect from "../FilterSelect/FilterSelect";
import MultiSelect from "../MultiSelect/MultiSelect";
import StackedChart from "./StackedChart";
import LogoutButton from "../LogoutButton/Logoutbutton";

const Charts = ({ selectedJobId }) => {
  const { getJobs, getJobById } = useJobs();

  const [jobs, setJobs] = useState([]);
  const [filteredJobs, setFilteredJobs] = useState([]);
  const [genderStats, setGenderStats] = useState({ male: 0, female: 0 });
  const [generalSelectedProgram, setGeneralSelectedProgram] = useState("");
  const [selectedProgram, setSelectedProgram] = useState("");
  const [selectedFilter, setSelectedFilter] = useState("day");
  const [selectedDates, setSelectedDates] = useState([]);
  const [chartSeries, setChartSeries] = useState([0, 0]);
  const [selectedProgramIds, setSelectedProgramIds] = useState([]);
  const [showExcel, setShowExcel] = useState(false);
  const [filterText, setFilterText] = useState("");
  const [totalProgramTime, setTotalProgramTime] = useState(0);
  const [categories, setCategories] = useState([]);

  useEffect(() => {
    const fetchJobs = async () => {
      try {
        const response = await getJobs();
        setJobs(response);
      } catch (error) {
        console.error("Error fetching jobs:", error.message);
      }
    };

    fetchJobs();
  }, [getJobs]);

  useEffect(() => {
    if (generalSelectedProgram) {
      const filtered = jobs.filter((job) =>
        job.titol.includes(generalSelectedProgram)
      );
      setFilteredJobs(filtered);
      setSelectedProgram("");
    } else {
      setFilteredJobs([]);
      setSelectedProgram("");
    }
  }, [generalSelectedProgram, jobs]);

  const calculateGenderTimeStats = (
    jobs,
    selectedFilter,
    selectedDates,
    generalSelectedProgram
  ) => {
    let maleTimes = {};
    let femaleTimes = {};
    let silenceTimes = {};

    
    const datesToCheck =
      selectedDates.length > 0
        ? selectedDates.map((d) =>
            selectedFilter === "week"
              ? `Setmana ${d.value || d}`
              : selectedFilter === "month"
              ? `Mes ${d.value || d}`
              : d.value || d
          )
        : jobs.map((job) => {
            const date = parse(job.date, "dd/MM/yyyy", new Date());
            if (selectedFilter === "week") {
              return `Setmana ${getISOWeek(date)}`;
            } else if (selectedFilter === "month") {
              return `Mes ${getMonth(date) + 1}`;
            } else {
              return format(date, "dd/MM/yyyy");
            }
          });


    jobs.forEach((job) => {
      const dateStr = job.date;

      if (!dateStr || !dateStr.match(/^\d{2}\/\d{2}\/\d{4}$/)) {
        console.warn("Formato de fecha inválido:", dateStr);
        return;
      }

      const date = parse(dateStr, "dd/MM/yyyy", new Date());
      let periodLabel;

      if (selectedFilter === "week") {
        periodLabel = `Setmana ${getISOWeek(date)}`;
      } else if (selectedFilter === "month") {
        periodLabel = `Mes ${getMonth(date) + 1}`;
      } else if (selectedFilter === "day") {
        periodLabel = format(date, "dd/MM/yyyy");
      }

      const isDateInSelectedRange = datesToCheck.some(
        (selectedDate) => selectedDate === periodLabel
      );


      if (!isDateInSelectedRange) {
        return;
      }

      if (!maleTimes[periodLabel]) maleTimes[periodLabel] = 0;
      if (!femaleTimes[periodLabel]) femaleTimes[periodLabel] = 0;
      if (!silenceTimes[periodLabel]) silenceTimes[periodLabel] = 0;

      const segments = job.results[0] || [];
      segments.sort((a, b) => a.start - b.start);

      let lastEnd = 0;

      segments.forEach((segment) => {
        const duration = segment.end - segment.start;

        if (segment.start > lastEnd) {
          silenceTimes[periodLabel] += segment.start - lastEnd;
        }

        if (segment.gender === "MALE") {
          maleTimes[periodLabel] += duration;
        } else if (segment.gender === "FEMALE") {
          femaleTimes[periodLabel] += duration;
        }

        lastEnd = Math.max(lastEnd, segment.end);
      });
    });

    let maleData = [];
    let femaleData = [];
    let silenceData = [];


    const periods = Object.keys(maleTimes);

    periods.forEach((label) => {
      const totalPeriodTime =
        maleTimes[label] + femaleTimes[label] + silenceTimes[label];
      const totalPeriodTimeWithoutSilence =
        maleTimes[label] + femaleTimes[label];


      if (totalPeriodTimeWithoutSilence > 0) {
        maleData.push(
          parseFloat(
            ((maleTimes[label] / totalPeriodTimeWithoutSilence) * 100).toFixed(
              2
            )
          )
        );
        femaleData.push(
          parseFloat(
            (
              (femaleTimes[label] / totalPeriodTimeWithoutSilence) *
              100
            ).toFixed(2)
          )
        );
      } else {
        maleData.push(0);
        femaleData.push(0);
      }
    });

    return {
      categories: periods,
      maleData,
      femaleData,
      silenceData,
      maleTime: Object.values(maleTimes).reduce((a, b) => a + b, 0),
      femaleTime: Object.values(femaleTimes).reduce((a, b) => a + b, 0),
      silenceTime: Object.values(silenceTimes).reduce((a, b) => a + b, 0),
    };
  };
 

  useEffect(() => {
    if (!filteredJobs.length || !selectedFilter) {
      setChartSeries([0, 0]);
      setCategories([]);
      return;
    }

    const stats = calculateGenderTimeStats(
      filteredJobs,
      selectedFilter,
      selectedDates,
      generalSelectedProgram
    );

    if (!stats.categories.length) {
      setCategories([]);
      setChartSeries([0, 0]);
      return;
    }

    let malePercentage = 0;
    let femalePercentage = 0;

    if (selectedFilter === "day") {
      malePercentage = stats.maleData[0] || 0;
      femalePercentage = stats.femaleData[0] || 0;
    } else {
      const totalMale = stats.maleData.reduce((sum, val) => sum + val, 0);
      const totalFemale = stats.femaleData.reduce((sum, val) => sum + val, 0);
      const total = totalMale + totalFemale;

      malePercentage = total > 0 ? (totalMale / total) * 100 : 0;
      femalePercentage = total > 0 ? (totalFemale / total) * 100 : 0;
    }


    setChartSeries([malePercentage, femalePercentage]);
    setCategories(stats.categories);
  }, [selectedFilter, selectedDates, filteredJobs, generalSelectedProgram]);

  const formatJobTitle = (titol) => {
    const parts = titol.split(" - ");
    return parts[0].trim();
  };

  const getUniqueWeeksAndMonths = () => {
    let uniqueWeeks = new Set();
    let uniqueMonths = new Set();

    filteredJobs.forEach((job) => {
      const dateStr = job.date;
      if (!dateStr || !dateStr.match(/^\d{2}\/\d{2}\/\d{4}$/)) return;
      const date = parse(dateStr, "dd/MM/yyyy", new Date());

      if (selectedFilter === "week") {
        uniqueWeeks.add(getISOWeek(date));
      } else if (selectedFilter === "month") {
        uniqueMonths.add(getMonth(date) + 1);
      }
    });

    return selectedFilter === "week"
      ? Array.from(uniqueWeeks)
      : Array.from(uniqueMonths);
  };

 
  const uniqueDates = getUniqueWeeksAndMonths().map((dateValue) => ({
    value: dateValue, 
    label:
      selectedFilter === "week" ? `Setmana ${dateValue}` : `Mes ${dateValue}`,
  }));


  const programOptions = Array.from(
    new Set(jobs.map((job) => formatJobTitle(job.titol)))
  ).map((titulo) => ({ value: titulo, label: titulo }));

  const filteredProgramOptions = filteredJobs.map((job) => ({
    value: job.titol,
    label: job.titol,
  }));

  const chartOptions = useMemo(
    () => ({
      chart: {
        toolbar: {
          show: false,
        },
        type: "pie",
        width: "100%",
      },
      labels: ["HOMES", "DONES"],
      dataLabels: {
        enabled: true,
        formatter: function (val, opts) {
          const seriesValue = opts.w.config.series[opts.seriesIndex];
          return seriesValue.toFixed(2) + "%";
        },
      },
      responsive: [
        {
          breakpoint: 480,
          options: {
            chart: {
              width: "100%",
            },
            legend: {
              position: "bottom",
            },
          },
        },
      ],
    }),
    [chartSeries]
  );

  const sortedFilteredJobs = filteredJobs.sort((a, b) => {
    const dateA = parse(a.date, "dd/MM/yyyy", new Date());
    const dateB = parse(b.date, "dd/MM/yyyy", new Date());

    return dateA - dateB;
  });

  const handleProgramChange = (program) => {
    setSelectedProgram(program);

    if (selectedFilter === "day") {
      const job = filteredJobs.find((job) => job.titol === program);
      if (job) {
        setSelectedDates([job.date]);
      }
    }
  };

  const handleGeneralProgramChange = (program) => {
    setGeneralSelectedProgram(program);
    const filtered = jobs.filter((job) => job.titol.includes(program));
    setFilteredJobs(filtered);
    setSelectedProgram("");
    setSelectedDates([]);
  };

  const handleFilterChange = (filter) => {
    setSelectedFilter(filter);
    if (filter === "day" && selectedProgram) {
      setSelectedDates([selectedProgram]);
    } else {
      setSelectedDates([]);
    }
  };

  const calculateTotalTimeProgram = (job) => {
    let totalTime = 0;

    if (job.results.length === 0) return totalTime;

    for (let i = 0; i < job.results.length; i++) {
      const segmentos = job.results[i];

      if (segmentos.length === 0) continue;

      segmentos.sort((a, b) => a.start - b.start);

      let currentStart = segmentos[0].start;
      let currentEnd = segmentos[0].end;

      for (let j = 1; j < segmentos.length; j++) {
        const segmento = segmentos[j];

        if (segmento.start > currentEnd) {
          totalTime += currentEnd - currentStart;

          currentStart = segmento.start;
          currentEnd = segmento.end;
        } else {
          currentEnd = Math.max(currentEnd, segmento.end);
        }
      }

      totalTime += currentEnd - currentStart;
    }

    return totalTime;
  };

  const calculateTotalTimeIncludingSilence = (segments) => {
    let totalSpeakTime = 0;
    let totalSilenceTime = 0;

    if (segments.length === 0) return { totalSpeakTime, totalSilenceTime };

    segments.sort((a, b) => a.start - b.start);

    let currentStart = segments[0].start;
    let currentEnd = segments[0].end;

    for (let i = 1; i < segments.length; i++) {
      const segment = segments[i];
      if (segment.start > currentEnd) {
        totalSilenceTime += segment.start - currentEnd;

        totalSpeakTime += currentEnd - currentStart;

        currentStart = segment.start;
        currentEnd = segment.end;
      } else {
        currentEnd = Math.max(currentEnd, segment.end);
      }
    }

    totalSpeakTime += currentEnd - currentStart;

    return { totalSpeakTime, totalSilenceTime };
  };

  const formatTime = (totalSeconds) => {
    const hours = Math.floor(totalSeconds / 3600);
    const minutes = Math.floor((totalSeconds % 3600) / 60);
    const seconds = (totalSeconds % 60).toFixed(2);

    return `${hours} hores, ${minutes} minuts i ${seconds} segons`;
  };

  useEffect(() => {
    if (selectedProgram) {
      const selectedJob = filteredJobs.find(
        (job) => job.titol === selectedProgram
      );
      if (selectedJob) {
        const { totalSpeakTime } = calculateTotalTimeProgram(selectedJob);
        setTotalProgramTime(totalSpeakTime);
      }
    }
  }, [selectedProgram, filteredJobs]);

  const calculateStatsExcludingTopSpeaker = (job) => {
    if (!job || !job.results || job.results.length === 0) {
      console.warn("El job no tiene resultados válidos.");
      return null;
    }
  
    const segments = job.results[0] || [];
    if (segments.length === 0) {
      console.warn("No hay segmentos para calcular.");
      return null;
    }
  
    const speakerDurations = {};
    let totalOriginalTime = 0;
  
    segments.forEach((segment) => {
      const duration = segment.end - segment.start;
      totalOriginalTime += duration;
      const speakerKey = String(segment.speaker).trim();
      speakerDurations[speakerKey] =
        (speakerDurations[speakerKey] || 0) + duration;
    });
  
    const topSpeaker = Object.keys(speakerDurations).reduce((a, b) =>
      speakerDurations[a] > speakerDurations[b] ? a : b
    );
  
    const topSpeakerTime = speakerDurations[topSpeaker];
    const topSpeakerPercentage = (topSpeakerTime / totalOriginalTime) * 100;
  
    const topSpeakerString = String(topSpeaker).trim();
  
    const topSpeakerSegment = segments.find(
      (segment) => String(segment.speaker).trim() === topSpeakerString
    );
  
    const topSpeakerGender = topSpeakerSegment
      ? topSpeakerSegment.gender || "Desconocido"
      : "Desconocido";
  
    const remainingSegments = segments.filter(
      (segment) => String(segment.speaker).trim() !== topSpeakerString
    );
  
    // Calcular tiempos por género
    const genderTimes = remainingSegments.reduce(
      (acc, segment) => {
        const duration = segment.end - segment.start;
        if (segment.gender === "MALE") {
          acc.maleTime += duration;
        } else if (segment.gender === "FEMALE") {
          acc.femaleTime += duration;
        } else {
          acc.otherTime += duration;
        }
        return acc;
      },
      { maleTime: 0, femaleTime: 0, otherTime: 0 }
    );
  
    // Excluir otherTime del total
    const totalTimeExcludingTopSpeaker =
      genderTimes.maleTime + genderTimes.femaleTime;
  
    // Calcular porcentajes
    const recalculatedMalePercentage =
      (genderTimes.maleTime / totalTimeExcludingTopSpeaker) * 100;
    const recalculatedFemalePercentage =
      (genderTimes.femaleTime / totalTimeExcludingTopSpeaker) * 100;
  
    return {
      maleTime: genderTimes.maleTime,
      femaleTime: genderTimes.femaleTime,
      totalOriginalTime,
      totalTimeExcludingTopSpeaker,
      malePercentage: recalculatedMalePercentage,
      femalePercentage: recalculatedFemalePercentage,
      topSpeakerPercentage,
      originalTopSpeaker: topSpeaker,
      topSpeakerGender: topSpeakerGender,
    };
  };
  

  //descagrar excel

  const sanitizeSheetName = (name) => {
    return name.replace(/[:\\\/\?\*\[\]]/g, "").substring(0, 31);
  };

  const handleSelectPrograms = (event) => {
    const selectedOptions = Array.from(event.target.selectedOptions);
    const ids = selectedOptions.map((option) => option.value);
    setSelectedProgramIds(ids);
  };

  const handleExportByDay = async () => {
    const workbook = XLSX.utils.book_new();
    const normalizedProgramName = generalSelectedProgram.trim().toLowerCase();

    const selectedProgramElements = jobs.filter((job) =>
      job.titol.trim().toLowerCase().includes(normalizedProgramName)
    );

    for (const job of selectedProgramElements) {
      try {
        const stats = calculateGenderTimeStats(
          [job],
          selectedFilter,
          [],
          generalSelectedProgram
        );

        if (!stats.maleData.length || !stats.femaleData.length) {
          console.warn(`No data available for program: ${job.titol}`);
          continue;
        }

        const totalTime = calculateTotalTimeProgram(job);
        const formattedTime = formatTime(totalTime);

        const programData = [
          { Category: "Homes", Percentage: stats.maleData[0] + "%" },
          { Category: "Dones", Percentage: stats.femaleData[0] + "%" },
        ];

        const worksheet = XLSX.utils.json_to_sheet([]);
        XLSX.utils.sheet_add_aoa(worksheet, [
          [`Programa: ${job.titol}`],
          [`Data: ${job.date}`],
          [`Temps total del programa: ${formattedTime}`],
          ["Category", "Percentage"],
        ]);

        XLSX.utils.sheet_add_json(worksheet, programData, {
          origin: "A5",
          skipHeader: true,
        });

        const sanitizedSheetName = sanitizeSheetName(`${job.titol}`);
        XLSX.utils.book_append_sheet(workbook, worksheet, sanitizedSheetName);
      } catch (error) {
        console.error(`Error processing program ${job.titol}:`, error.message);
      }
    }

    if (workbook.SheetNames.length === 0) {
      console.error("No hay datos para exportar");
      return;
    }

    XLSX.writeFile(
      workbook,
      "Gender_Distribution_Pie_ByDay_Including_Top_Speaker.xlsx"
    );
  };

  const handleExportByWeekOrMonth = async () => {
    const workbook = XLSX.utils.book_new();

    const filteredPrograms = filteredJobs.filter((job) => {
      const jobDate = parse(job.date, "dd/MM/yyyy", new Date());
      if (selectedFilter === "week") {
        const weekNumber = getISOWeek(jobDate);
        return selectedDates.some((date) => date.value === weekNumber);
      } else if (selectedFilter === "month") {
        const monthNumber = getMonth(jobDate) + 1;
        return selectedDates.some((date) => date.value === monthNumber);
      }
      return false;
    });

    let summaryData = {};

    for (const job of filteredPrograms) {
      try {
        const stats = calculateGenderTimeStats(
          [job],
          selectedFilter,
          [],
          generalSelectedProgram
        );

        if (!stats.maleData.length || !stats.femaleData.length) {
          console.warn(`No data available for program: ${job.titol}`);
          continue;
        }

        const totalTime = calculateTotalTimeProgram(job);
        const formattedTime = formatTime(totalTime);

        const jobDate = parse(job.date, "dd/MM/yyyy", new Date());
        const key =
          selectedFilter === "week"
            ? `Setmana ${getISOWeek(jobDate)}`
            : `Mes ${getMonth(jobDate) + 1}`;

        if (!summaryData[key]) {
          summaryData[key] = {
            totalMaleTime: 0,
            totalFemaleTime: 0,
            totalTime: 0,
          };
        }

        summaryData[key].totalMaleTime += stats.maleTime;
        summaryData[key].totalFemaleTime += stats.femaleTime;
        summaryData[key].totalTime += stats.maleTime + stats.femaleTime;

        const programData = [
          { Category: "Homes", Percentage: stats.maleData[0] + "%" },
          { Category: "Dones", Percentage: stats.femaleData[0] + "%" },
        ];

        const worksheet = XLSX.utils.json_to_sheet([]);
        XLSX.utils.sheet_add_aoa(worksheet, [
          [`Programa: ${job.titol}`],
          [`Data: ${job.date}`],
          [`Temps total del programa: ${formattedTime}`],
          ["Category", "Percentage"],
        ]);

        XLSX.utils.sheet_add_json(worksheet, programData, {
          origin: "A5",
          skipHeader: true,
        });

        const sanitizedSheetName = sanitizeSheetName(`${job.titol}`);
        XLSX.utils.book_append_sheet(workbook, worksheet, sanitizedSheetName);
      } catch (error) {
        console.error(`Error processing program ${job.titol}:`, error.message);
      }
    }

    Object.entries(summaryData).forEach(([key, data]) => {
      const { totalMaleTime, totalFemaleTime, totalTime } = data;
      const malePercentage =
        totalTime > 0 ? (totalMaleTime / totalTime) * 100 : 0;
      const femalePercentage =
        totalTime > 0 ? (totalFemaleTime / totalTime) * 100 : 0;

      const keyData = [
        [`Resum de ${key}`],
        [`Temps Total: ${formatTime(totalTime)}`],
        ["Categoria", "Percentatge"],
        ["Homes", `${malePercentage.toFixed(2)}%`],
        ["Dones", `${femalePercentage.toFixed(2)}%`],
      ];

      const worksheet = XLSX.utils.aoa_to_sheet(keyData);
      const sanitizedSheetName = sanitizeSheetName(key);
      XLSX.utils.book_append_sheet(workbook, worksheet, sanitizedSheetName);
    });

    if (workbook.SheetNames.length === 0) {
      console.error("No hay datos para exportar");
      return;
    }

    XLSX.writeFile(
      workbook,
      "Gender_Distribution_Pie_ByWeekOrMonth_Including_Top_Speaker.xlsx"
    );
  };


  const handleExportExcludingTopSpeaker = async () => {
    if (!generalSelectedProgram) {
      console.error("No hay programa seleccionado");
      return;
    }
  
    const workbook = XLSX.utils.book_new();
    const normalizedProgramName = generalSelectedProgram.trim().toLowerCase();
    const selectedProgramElements = jobs.filter((job) =>
      job.titol.trim().toLowerCase().includes(normalizedProgramName)
    );
  
    for (const job of selectedProgramElements) {
      try {
        const stats = calculateStatsExcludingTopSpeaker(job);
  
        if (!stats) {
          console.error(
            `No se pudieron calcular estadísticas para el programa ${job.titol}`
          );
          continue;
        }
        
        const totalTime = calculateTotalTimeProgram(job)
        const formattedTime = formatTime(totalTime);
  
        const programData = [
          {
            Category: "Homes",
            Percentage: stats.malePercentage.toFixed(2) + "%",
          },
          {
            Category: "Dones",
            Percentage: stats.femalePercentage.toFixed(2) + "%",
          },
        ];
  
        const worksheet = XLSX.utils.json_to_sheet([]);
        XLSX.utils.sheet_add_aoa(worksheet, [
          [`Programa: ${job.titol}`],
          [`Data: ${job.date}`],
          //[`Temps excloent top speaker: ${formattedTime}`],
          [`Top Speaker Exclòs: ${stats.originalTopSpeaker}`],
          ["Category", "Percentage"],
        ]);
  
        XLSX.utils.sheet_add_json(worksheet, programData, {
          origin: "A6",
          skipHeader: true,
        });
  
        const sanitizedSheetName = sanitizeSheetName(`${job.titol}`);
        XLSX.utils.book_append_sheet(workbook, worksheet, sanitizedSheetName);
      } catch (error) {
        console.error(`Error processing program ID ${job.id}:`, error.message);
      }
    }
  
    if (workbook.SheetNames.length === 0) {
      console.error("No hay datos para exportar.");
      return;
    }
  
    XLSX.writeFile(
      workbook,
      "Gender_Distribution_Pie_ByDay_Excluding_Top_Speaker.xlsx"
    );
  };
  

  const handleFilterExcelChange = (event) => {
    setFilterText(event.target.value.toLowerCase());
  };

  const filteredJobOptions = jobs.filter((job) =>
    job.titol.toLowerCase().includes(filterText)
  );


  const handleExportStatsExcludingTopSpeaker = () => {
    const workbook = XLSX.utils.book_new();
  
    const programsToProcess = filteredJobs.filter((job) => {
      const jobDate = parse(job.date, "dd/MM/yyyy", new Date());
  
      if (selectedFilter === "week") {
        const weekNumber = getISOWeek(jobDate);
        return selectedDates.some((date) => date.value === weekNumber);
      } else if (selectedFilter === "month") {
        const monthNumber = getMonth(jobDate) + 1;
        return selectedDates.some((date) => date.value === monthNumber);
      } else if (selectedFilter === "day") {
        return selectedDates.some((date) => date.value === job.date);
      }
  
      return false;
    });
  
    if (programsToProcess.length === 0) {
      console.error("No hay programas seleccionados para exportar.");
      return;
    }
  
    let summaryData = [];
  
    for (const job of programsToProcess) {
      try {
        const stats = calculateStatsExcludingTopSpeaker(job);
  
        if (!stats) {
          console.warn(
            `No se pudieron calcular estadísticas para el programa ${job.titol}`
          );
          continue;
        }
  
        const formattedTime = formatTime(stats.totalTimeExcludingTopSpeaker);
  
        summaryData.push({
          maleTime: stats.maleTime || 0,
          femaleTime: stats.femaleTime || 0,
          otherTime: stats.otherTime || 0,
          totalTime: stats.totalTimeExcludingTopSpeaker || 0,
        });
  
        const programData = [
          [`Programa: ${job.titol}`],
          [`Data: ${job.date}`],
          [`Speaker Exclòs: ${stats.originalTopSpeaker}`],
          ["Categoria", "Percentatge sense top speaker"],
          ["Homes", `${stats.malePercentage > 0 ? stats.malePercentage.toFixed(2) : 0}%`],
          ["Dones", `${stats.femalePercentage > 0 ? stats.femalePercentage.toFixed(2) : 0}%`],
        ];
  
        const worksheet = XLSX.utils.aoa_to_sheet(programData);
        const columnWidths = [{ wch: 40 }, { wch: 20 }];
        worksheet["!cols"] = columnWidths;
  
        const sanitizedSheetName = sanitizeSheetName(`${job.date}`);
        XLSX.utils.book_append_sheet(workbook, worksheet, sanitizedSheetName);
      } catch (error) {
        console.error(
          `Error procesando programa ${job.titol}: ${error.message}`
        );
      }
    }
  
    if (summaryData.length > 0) {
      const totalProgramTimeExcludingTop = summaryData.reduce(
        (acc, val) => acc + (isNaN(val.totalTime) ? 0 : val.totalTime),
        0
      );
  
      const totalMaleTimeExcludingTop = summaryData.reduce(
        (acc, val) => acc + (isNaN(val.maleTime) ? 0 : val.maleTime),
        0
      );
  
      const totalFemaleTimeExcludingTop = summaryData.reduce(
        (acc, val) => acc + (isNaN(val.femaleTime) ? 0 : val.femaleTime),
        0
      );
  
      const totalOtherTimeExcludingTop = summaryData.reduce(
        (acc, val) => acc + (isNaN(val.otherTime) ? 0 : val.otherTime),
        0
      );
  
      const totalMalePercentageExcludingTop =
        totalProgramTimeExcludingTop > 0
          ? (totalMaleTimeExcludingTop / totalProgramTimeExcludingTop) * 100
          : 0;
  
      const totalFemalePercentageExcludingTop =
        totalProgramTimeExcludingTop > 0
          ? (totalFemaleTimeExcludingTop / totalProgramTimeExcludingTop) * 100
          : 0;
  
      const totalOtherPercentageExcludingTop =
        totalProgramTimeExcludingTop > 0
          ? (totalOtherTimeExcludingTop / totalProgramTimeExcludingTop) * 100
          : 0;
  
      const summaryDataSheet = [
        ["Resum de les setmanes/mesos seleccionats (excloent top speaker)"],
        [`Temps total: ${formatTime(totalProgramTimeExcludingTop)}`],
        [
          `Homes: ${totalMalePercentageExcludingTop.toFixed(
            2
          )}%`,
        ],
        [
          `Dones: ${totalFemalePercentageExcludingTop.toFixed(
            2
          )}%`,
        ],
       
      ];
  
      const summarySheet = XLSX.utils.aoa_to_sheet(summaryDataSheet);
      XLSX.utils.book_append_sheet(workbook, summarySheet, "Resum Agregat");
    }
  
    if (workbook.SheetNames.length === 0) {
      console.error("No hay datos para exportar");
      return;
    }
  
    XLSX.writeFile(
      workbook,
      "Gender_Distribution_Pie_ByWeekOrMonth_Excluding_Top_Speaker.xlsx"
    );
  };
  

  return (
    <>
      <LogoutButton />
      <Header />
      <div
        id="chart"
        className="w-full max-w-screen-lg mx-auto overflow-x-auto"
      >
        <main className="p-4 min-h-screen w-full">
          <div className="flex justify-end mb-4">
            <Button to="/">
              <img src="/img/icon_arrow.png" alt="Torna" />
            </Button>
          </div>
          <div>
            <div className="m-12">
              <span className="flex text-black text-base font-bold justify-center">
                Selecciona primer el programa que vols visualitzar i després el
                dia a través d’aquests filtres:
              </span>
              <FilterSelect
                options={programOptions}
                selectedValue={generalSelectedProgram}
                onChange={(option) => handleGeneralProgramChange(option.value)}
              />
              {generalSelectedProgram && (
                <div>
                  <FilterSelect
                    options={filteredProgramOptions}
                    selectedValue={selectedProgram}
                    onChange={(option) => handleProgramChange(option.value)}
                  />
                </div>
              )}
            </div>
          </div>
          <div className="mb-4">
            <div className="flex justify-center">
              <SpeakersChart
                selectedJobId={selectedJobId}
                generalSelectedProgram={generalSelectedProgram}
                selectedProgram={selectedProgram}
              />
            </div>
            <h1 className="text-xs text-black">
              Percentatges de sexe per dia, setmana o mes
            </h1>
            <span className="italic text-xs text-black">
              Selecciona entre "dia", "setmana" o "mes" per obtindre resultats
              del programa seleccionat anteriorment.
            </span>
          </div>
          <div className="flex flex-row mb-4">
            <FilterSelect
              options={[
                { value: "day", label: "Dia" },
                { value: "week", label: "Setmana" },
                { value: "month", label: "Mes" },
              ]}
              selectedValue={selectedFilter}
              onChange={(option) => handleFilterChange(option.value)}
            />
            {selectedFilter && selectedFilter !== "day" && (
              <div className="mb-4">
                <MultiSelect
                  options={uniqueDates}
                  selectedValues={selectedDates}
                  onChange={setSelectedDates}
                />
              </div>
            )}
          </div>

          <div className="flex flex-col justify-center space-y-6 md:space-y-8 lg:space-y-10">
            <div className="flex justify-center">
              <ReactApexChart
                options={chartOptions}
                series={chartSeries}
                type="pie"
                width="100%"
              />
            </div>

            <div className="flex flex-row items-start m-4 gap-2">
              {selectedFilter === "day" ? (
                <>
                  <button
                    onClick={handleExportByDay}
                    className="text-sm px-4 py-2 bg-gray-300 text-gray-800 rounded hover:bg-gray-400"
                  >
                    Excel inclou speakers
                  </button>
                  <button
                    // onClick={handleExportStatsExcludingTopSpeaker}
                    onClick={handleExportExcludingTopSpeaker}
                    className="text-sm px-4 py-2 bg-gray-300 text-gray-800 rounded hover:bg-gray-400"
                  >
                    Excel exclou top speaker
                  </button>
                </>
              ) : (
                <>
                  <button
                    onClick={handleExportByWeekOrMonth}
                    className="text-sm px-4 py-2 bg-gray-300 text-gray-800 rounded hover:bg-gray-400"
                  >
                    Excel inclou speakers
                  </button>
                  <button
                    onClick={handleExportStatsExcludingTopSpeaker}
                    // onClick={handleExportExcludingTopSpeaker}
                    className="text-sm px-4 py-2 bg-gray-300 text-gray-800 rounded hover:bg-gray-400"
                  >
                    Excel exclou top speaker
                  </button>
                </>
              )}
            </div>

            <div className="flex justify-center">
              <StackedChart
                selectedJobId={selectedJobId}
                generalSelectedProgram={generalSelectedProgram}
                selectedProgram={selectedProgram}
              />
            </div>
            <div className="flex justify-center">
              <HeatmapChart
                selectedJobId={selectedJobId}
                generalSelectedProgram={generalSelectedProgram}
                selectedProgram={selectedProgram}
              />
            </div>
          </div>
        </main>
      </div>
    </>
  );
};

export default Charts;
