import * as XLSX from "xlsx";
import React, { useEffect, useState } from "react";
import ReactApexChart from "react-apexcharts";
import { parse, getISOWeek, getMonth, format, isValid } from "date-fns";
import { useJobs } from "../../hooks/useJobs/useJobs";
import FilterSelect from "../FilterSelect/FilterSelect";
import MultiSelect from "../MultiSelect/MultiSelect";

const StackedChart = ({
  generalSelectedProgram,
  selectedProgram,
  setSelectedProgram,
}) => {
  const { getJobs } = useJobs();

  const [jobs, setJobs] = useState([]);
  const [filteredJobs, setFilteredJobs] = useState([]);
  const [selectedFilter, setSelectedFilter] = useState("day");
  const [selectedDates, setSelectedDates] = useState([]);
  const [chartSeries, setChartSeries] = useState([]);
  const [categories, setCategories] = useState([]);
  const [uniqueWeeksAndMonths, setUniqueWeeksAndMonths] = useState([]);
  const [uniqueDays, setUniqueDays] = useState([]);
  const [selectedProgramIds, setSelectedProgramIds] = useState([]);
  const [filterText, setFilterText] = useState("");
  const [totalProgramTime, setTotalProgramTime] = useState(0);
  const [maleTimesState, setMaleTimesState] = useState({});
  const [femaleTimesState, setFemaleTimesState] = useState({});
  const [silenceTimesState, setSilenceTimesState] = useState({});

  useEffect(() => {
    const fetchJobs = async () => {
      try {
        const response = await getJobs();
        setJobs(response);
      } catch (error) {
        console.error("Error fetching jobs:", error.message);
      }
    };

    fetchJobs();
  }, [getJobs]);

  useEffect(() => {
    if (generalSelectedProgram) {
      const filtered = jobs.filter((job) =>
        job.titol.includes(generalSelectedProgram)
      );
      setFilteredJobs(filtered);
    } else {
      setFilteredJobs([]);
    }
  }, [generalSelectedProgram, jobs]);

  useEffect(() => {
    const getUniqueWeeksAndMonths = () => {
      let uniqueValues = new Set();

      filteredJobs.forEach((job) => {
        const dateStr = job.date;
        if (!dateStr || !dateStr.match(/^\d{2}\/\d{2}\/\d{4}$/)) return;
        const date = parse(dateStr, "dd/MM/yyyy", new Date());

        if (
          selectedFilter === "SETMANA" ||
          selectedFilter === "SETMANA - DIES"
        ) {
          const weekNumber = getISOWeek(date);
          const weekLabel = `Setmana ${weekNumber}`;
          uniqueValues.add(weekLabel);
        } else if (
          selectedFilter === "MES" ||
          selectedFilter === "MES - DIES"
        ) {
          const monthNumber = getMonth(date) + 1;
          const monthLabel = `Mes ${monthNumber}`;
          uniqueValues.add(monthLabel);
        } else if (selectedFilter === "day") {
          const dayLabel = format(date, "dd/MM/yyyy");
          uniqueValues.add(dayLabel);
        }
      });

      return Array.from(uniqueValues).map((value) => ({
        value: value,
        label: value,
      }));
    };

    setUniqueWeeksAndMonths(getUniqueWeeksAndMonths());
    setUniqueDays(
      getUniqueWeeksAndMonths().filter((value) =>
        value.label.match(/^\d{2}\/\d{2}\/\d{4}$/)
      )
    );
  }, [selectedFilter, filteredJobs]);

  const calculateTotalTimeIncludingSilence = (segments) => {
    let totalSpeakTime = 0;
    let totalSilenceTime = 0;

    if (segments.length === 0) return { totalSpeakTime, totalSilenceTime };

    segments.sort((a, b) => a.start - b.start);

    let currentStart = segments[0].start;
    let currentEnd = segments[0].end;

    for (let i = 1; i < segments.length; i++) {
      const segment = segments[i];
      if (segment.start > currentEnd) {
        totalSilenceTime += segment.start - currentEnd;

        totalSpeakTime += currentEnd - currentStart;

        currentStart = segment.start;
        currentEnd = segment.end;
      } else {
        currentEnd = Math.max(currentEnd, segment.end);
      }
    }

    totalSpeakTime += currentEnd - currentStart;

    return { totalSpeakTime, totalSilenceTime };
  };

  useEffect(() => {
    const calculateGenderStats = async () => {
      if (!selectedFilter) {
        setChartSeries([]);
        setCategories([]);
        return;
      }

      let maleTimes = {};
      let femaleTimes = {};
      let silenceTimes = {};

      const jobsInSelectedDates = filteredJobs.filter((job) => {
        const dateStr = job.date;
        if (!dateStr || !dateStr.match(/^\d{2}\/\d{2}\/\d{4}$/)) return false;

        const date = parse(dateStr, "dd/MM/yyyy", new Date());
        if (selectedFilter === "SETMANA") {
          const weekNumber = getISOWeek(date);
          return selectedDates.some(
            (selectedDate) => selectedDate.value === `Setmana ${weekNumber}`
          );
        } else if (selectedFilter === "SETMANA - DIES") {
          const weekNumber = getISOWeek(date);
          const weekLabel = `Setmana ${weekNumber}`;
          return selectedDates.some(
            (selectedDate) =>
              selectedDate.value === weekLabel ||
              selectedDate.value === format(date, "dd/MM/yyyy")
          );
        } else if (selectedFilter === "MES") {
          const monthNumber = getMonth(date) + 1;
          return selectedDates.some(
            (selectedDate) => selectedDate.value === `Mes ${monthNumber}`
          );
        } else if (selectedFilter === "MES - DIES") {
          const monthNumber = getMonth(date) + 1;
          const monthLabel = `Mes ${monthNumber}`;
          return selectedDates.some(
            (selectedDate) =>
              selectedDate.value === monthLabel ||
              selectedDate.value === format(date, "dd/MM/yyyy")
          );
        } else if (selectedFilter === "day") {
          return selectedDates.some(
            (selectedDate) => selectedDate.value === format(date, "dd/MM/yyyy")
          );
        }
        return false;
      });

      jobsInSelectedDates.forEach((job) => {
        const dateStr = job.date;
        const date = parse(dateStr, "dd/MM/yyyy", new Date());

        let periodLabel;

        if (selectedFilter === "SETMANA") {
          const weekNumber = getISOWeek(date);
          periodLabel = `Setmana ${weekNumber}`;
        } else if (selectedFilter === "SETMANA - DIES") {
          const weekNumber = getISOWeek(date);
          const weekLabel = `Setmana ${weekNumber}`;
          periodLabel = `${weekLabel} - ${format(date, "dd/MM/yyyy")}`;
        } else if (selectedFilter === "MES") {
          const monthNumber = getMonth(date) + 1;
          periodLabel = `Mes ${monthNumber}`;
        } else if (selectedFilter === "MES - DIES") {
          const monthNumber = getMonth(date) + 1;
          const monthLabel = `Mes ${monthNumber}`;
          periodLabel = `${monthLabel} - ${format(date, "dd/MM/yyyy")}`;
        } else if (selectedFilter === "day") {
          periodLabel = format(date, "dd/MM/yyyy");
        }

        if (periodLabel) {
          if (!maleTimes[periodLabel]) maleTimes[periodLabel] = 0;
          if (!femaleTimes[periodLabel]) femaleTimes[periodLabel] = 0;
          if (!silenceTimes[periodLabel]) silenceTimes[periodLabel] = 0;

          const { totalSpeakTime, totalSilenceTime } =
            calculateTotalTimeIncludingSilence(job.results[0]);

          job.results[0].forEach((result) => {
            const duration = result.end - result.start;
            if (result.gender === "MALE") {
              maleTimes[periodLabel] += duration;
            } else if (result.gender === "FEMALE") {
              femaleTimes[periodLabel] += duration;
            }
          });

          silenceTimes[periodLabel] += totalSilenceTime;
        }
      });

      setMaleTimesState(maleTimes);
      setFemaleTimesState(femaleTimes);
      setSilenceTimesState(silenceTimes);

      let newCategories = Object.keys(maleTimes);
      let maleData = [];
      let femaleData = [];
      let silenceData = [];

      newCategories.forEach((label) => {
        /* const totalPeriodTime =
          maleTimes[label] + femaleTimes[label] + silenceTimes[label]; */
        const totalPeriodTimeWithoutSilence =
          maleTimes[label] + femaleTimes[label];
        /* if (totalPeriodTime > 0) {
        maleData.push(
          ((maleTimes[label] / totalPeriodTime) * 100).toFixed(2)
        );
        femaleData.push(
          ((femaleTimes[label] / totalPeriodTime) * 100).toFixed(2)
        );
        silenceData.push(
          ((silenceTimes[label] / totalPeriodTime) * 100).toFixed(2)
        ); */
        if (totalPeriodTimeWithoutSilence > 0) {
          maleData.push(
            ((maleTimes[label] / totalPeriodTimeWithoutSilence) * 100).toFixed(
              2
            )
          );
          femaleData.push(
            (
              (femaleTimes[label] / totalPeriodTimeWithoutSilence) *
              100
            ).toFixed(2)
          );
          silenceData.push(
            (
              (silenceTimes[label] / totalPeriodTimeWithoutSilence) *
              100
            ).toFixed(2)
          );
        } else {
          maleData.push(0);
          femaleData.push(0);
          silenceData.push(0);
        }
      });

      setCategories(newCategories);
      setChartSeries([
        {
          name: "HOMES",
          data: maleData,
        },
        {
          name: "DONES",
          data: femaleData,
        },
      ]);
    };

    calculateGenderStats();
  }, [
    selectedFilter,
    selectedDates,
    filteredJobs,
    selectedProgram,
    generalSelectedProgram,
  ]);

  useEffect(() => {
    if (selectedFilter === "day" && selectedProgram) {
      const programDay = filteredJobs.find(
        (job) => job.titol === selectedProgram
      );
      if (programDay) {
        const programDate = format(
          parse(programDay.date, "dd/MM/yyyy", new Date()),
          "dd/MM/yyyy"
        );
        setSelectedDates([{ value: programDate, label: programDate }]);
      }
    }
  }, [selectedFilter, selectedProgram, filteredJobs]);

  const sortedFilteredJobs = filteredJobs.sort((a, b) => {
    const dateA = parse(a.date, "dd/MM/yyyy", new Date());
    const dateB = parse(b.date, "dd/MM/yyyy", new Date());
    return dateA - dateB;
  });

  const handleFilterChange = (filter) => {
    setSelectedFilter(filter);
    if (filter === "day" && selectedProgram) {
      const programDay = filteredJobs.find(
        (job) => job.titol === selectedProgram
      );
      if (programDay) {
        const programDate = format(
          parse(programDay.date, "dd/MM/yyyy", new Date()),
          "dd/MM/yyyy"
        );
        setSelectedDates([{ value: programDate, label: programDate }]);
      } else {
        setSelectedDates([]);
      }
    } else {
      setSelectedDates([]);
    }
  };

  useEffect(() => {
    if (selectedProgram) {
      const programDay = filteredJobs.find(
        (job) => job.titol === selectedProgram
      );
      if (programDay) {
        const programDate = format(
          parse(programDay.date, "dd/MM/yyyy", new Date()),
          "dd/MM/yyyy"
        );
        setSelectedDates([{ value: programDate, label: programDate }]);
      }
    }
  }, [selectedProgram, filteredJobs]);

  const chartOptions = {
    chart: {
      type: "bar",
      stacked: true,
      height: 500,
      width: "1000",
    },
    xaxis: {
      categories: categories,
    },
    fill: {
      opacity: 1,
    },
    legend: {
      position: "top",
    },
    plotOptions: {
      bar: {
        horizontal: false,
      },
    },
    tooltip: {
      enabled: true,
      theme: "dark",
      style: {
        fontSize: "12px",
        colors: ["#fff"],
      },
      y: {
        formatter: function (val) {
          return `${val}%`;
        },
      },
    },
  };

  const calculateTotalTimeProgram = (job) => {
    let totalTime = 0;

    if (job.results.length === 0) return totalTime;

    for (let i = 0; i < job.results.length; i++) {
      const segmentos = job.results[i];

      if (segmentos.length === 0) continue;

      segmentos.sort((a, b) => a.start - b.start);

      let currentStart = segmentos[0].start;
      let currentEnd = segmentos[0].end;

      for (let j = 1; j < segmentos.length; j++) {
        const segmento = segmentos[j];

        if (segmento.start > currentEnd) {
          totalTime += currentEnd - currentStart;

          currentStart = segmento.start;
          currentEnd = segmento.end;
        } else {
          currentEnd = Math.max(currentEnd, segmento.end);
        }
      }

      totalTime += currentEnd - currentStart;
    }

    return totalTime;
  };

  const formatTime = (totalSeconds) => {
    const hours = Math.floor(totalSeconds / 3600);
    const minutes = Math.floor((totalSeconds % 3600) / 60);
    const seconds = (totalSeconds % 60).toFixed(2);

    return `${hours} hores, ${minutes} minuts i ${seconds} segons`;
  };

  useEffect(() => {
    if (selectedProgram) {
      const selectedJob = filteredJobs.find(
        (job) => job.titol === selectedProgram
      );
      if (selectedJob) {
        const totalTime = calculateTotalTimeProgram(selectedJob);

        setTotalProgramTime(totalTime);
      }
    }
  }, [selectedProgram, filteredJobs]);

  //empezamos con el excel

  const sanitizeSheetName = (name) => {
    return name.replace(/[:\\\/\?\*\[\]]/g, "").substring(0, 31);
  };

  const filteredJobsForExport = filteredJobs.filter((job) => {
    const date = parse(job.date, "dd/MM/yyyy", new Date());

    if (!isValid(date)) {
      console.warn(`Fecha inválida encontrada en el trabajo con ID: ${job.id}`);
      return false;
    }

    if (selectedFilter === "SETMANA") {
      const weekNumber = getISOWeek(date);
      return selectedDates.some(
        (date) => date.value === `Setmana ${weekNumber}`
      );
    } else if (selectedFilter === "MES") {
      const monthNumber = getMonth(date) + 1;
      return selectedDates.some((date) => date.value === `Mes ${monthNumber}`);
    } else if (selectedFilter === "day") {
      const formattedDate = format(date, "dd/MM/yyyy");
      return selectedDates.some((date) => date.value === formattedDate);
    }
    return false;
  });

  const handleExportByDay = async () => {
    const workbook = XLSX.utils.book_new();

    Object.keys(maleTimesState).forEach((periodLabel) => {
      const maleTime = maleTimesState[periodLabel];
      const femaleTime = femaleTimesState[periodLabel];
      const silenceTime = silenceTimesState[periodLabel] || 0;

      //const totalJobTime = maleTime + femaleTime + silenceTime;
      const totalJobTime = maleTime + femaleTime;

      const malePercentage =
        totalJobTime > 0 ? (maleTime / totalJobTime) * 100 : 0;
      const femalePercentage =
        totalJobTime > 0 ? (femaleTime / totalJobTime) * 100 : 0;

      const programData = [
        {
          Category: "Dones",
          Percentage: femalePercentage.toFixed(2) + "%",
        },
        {
          Category: "Homes",
          Percentage: malePercentage.toFixed(2) + "%",
        },
      ];

      const worksheet = XLSX.utils.json_to_sheet([]);
      XLSX.utils.sheet_add_aoa(worksheet, [
        [`Resum del període: ${periodLabel}`],
        ["Category", "Percentage"],
      ]);
      XLSX.utils.sheet_add_json(worksheet, programData, {
        origin: "A4",
        skipHeader: true,
      });

      XLSX.utils.book_append_sheet(
        workbook,
        worksheet,
        sanitizeSheetName(periodLabel)
      );
    });

    if (workbook.SheetNames.length === 0) {
      console.error("No data to export");
      return;
    }

    XLSX.writeFile(
      workbook,
      "Stacked_Gender_Distribution_Including_TopSpeaker_ByDay.xlsx"
    );
  };

  const filterByWeekAndDays = (jobs, selectedDates) => {
    return jobs.filter((job) => {
      const jobDate = parse(job.date, "dd/MM/yyyy", new Date());
      const weekNumber = getISOWeek(jobDate);
      const dayLabel = format(jobDate, "dd/MM/yyyy");

      const selectedWeek = `Setmana ${weekNumber}`;

      const isWeekSelected = selectedDates.some(
        (date) => date.value === selectedWeek
      );

      if (isWeekSelected) {
        return true;
      }

      const isDaySelected = selectedDates.some(
        (date) => date.value === dayLabel
      );

      return isDaySelected;
    });
  };

  const filterByMonthAndDays = (jobs, selectedDates) => {
    const selectedMonths = selectedDates.map((date) =>
      parseInt(date.value.split(" ")[1])
    );

    return jobs.filter((job) => {
      const jobDate = parse(job.date, "dd/MM/yyyy", new Date());
      const monthNumber = getMonth(jobDate) + 1;
      const dayLabel = format(jobDate, "dd/MM/yyyy");

      return selectedMonths.includes(monthNumber);
    });
  };

  const handleExportByWeekOrMonth = async () => {
    const workbook = XLSX.utils.book_new();

    let filteredPrograms = [];

    if (selectedFilter === "SETMANA - DIES") {
      filteredPrograms = filterByWeekAndDays(filteredJobs, selectedDates);
    } else if (selectedFilter === "MES - DIES") {
      filteredPrograms = filterByMonthAndDays(filteredJobs, selectedDates);
    } else {
      filteredPrograms = filteredJobs.filter((job) => {
        const jobDate = parse(job.date, "dd/MM/yyyy", new Date());
        const weekNumber = getISOWeek(jobDate);
        const monthNumber = getMonth(jobDate) + 1;

        return selectedDates.some((date) => {
          if (selectedFilter === "SETMANA") {
            const weekFromSelection = parseInt(date.value.split(" ")[1]);
            return weekNumber === weekFromSelection;
          } else if (selectedFilter === "MES") {
            const monthFromSelection = parseInt(date.value.split(" ")[1]);
            return monthNumber === monthFromSelection;
          }
          return false;
        });
      });
    }

    if (filteredPrograms.length === 0) {
      console.error("No data to export");
      return;
    }

    if (selectedFilter === "SETMANA" || selectedFilter === "MES") {
      let summary = {};

      filteredPrograms.forEach((job) => {
        const jobDate = parse(job.date, "dd/MM/yyyy", new Date());
        const timeLabel =
          selectedFilter === "SETMANA"
            ? `Setmana ${getISOWeek(jobDate)}`
            : `Mes ${getMonth(jobDate) + 1}`;

        if (!summary[timeLabel]) {
          summary[timeLabel] = {
            maleTime: 0,
            femaleTime: 0,
            silenceTime: 0,
          };
        }

        const { totalSpeakTime, totalSilenceTime } =
          calculateTotalTimeIncludingSilence(job.results[0]);

        summary[timeLabel].silenceTime += totalSilenceTime;

        job.results[0].forEach((result) => {
          const duration = result.end - result.start;
          if (result.gender === "MALE") {
            summary[timeLabel].maleTime += duration;
          } else if (result.gender === "FEMALE") {
            summary[timeLabel].femaleTime += duration;
          }
        });
      });

      Object.keys(summary).forEach((timeLabel) => {
        const { maleTime, femaleTime, silenceTime } = summary[timeLabel];
        //const totalJobTime = maleTime + femaleTime + silenceTime;
        const totalJobTime = maleTime + femaleTime;

        const malePercentage =
          totalJobTime > 0 ? (maleTime / totalJobTime) * 100 : 0;
        const femalePercentage =
          totalJobTime > 0 ? (femaleTime / totalJobTime) * 100 : 0;

        const programData = [
          { Category: "Dones", Percentage: femalePercentage.toFixed(2) + "%" },
          { Category: "Homes", Percentage: malePercentage.toFixed(2) + "%" },
        ];

        const worksheet = XLSX.utils.json_to_sheet([]);
        XLSX.utils.sheet_add_aoa(
          worksheet,
          [
            [`Resum de ${timeLabel}`],
            [`Total de temps: ${formatTime(totalJobTime)}`],
            ["Category", "Percentage"],
          ],
          { origin: "A1" }
        );

        XLSX.utils.sheet_add_json(worksheet, programData, {
          origin: "A4",
          skipHeader: true,
        });

        XLSX.utils.book_append_sheet(
          workbook,
          worksheet,
          sanitizeSheetName(timeLabel)
        );
      });
    } else if (
      selectedFilter === "SETMANA - DIES" ||
      selectedFilter === "MES - DIES"
    ) {
      let summaryByDays = {};

      filteredPrograms.forEach((job) => {
        const jobDate = parse(job.date, "dd/MM/yyyy", new Date());
        const timeLabel =
          selectedFilter === "SETMANA - DIES"
            ? `Setmana ${getISOWeek(jobDate)}`
            : `Mes ${getMonth(jobDate) + 1}`;
        const dayLabel = format(jobDate, "dd/MM/yyyy");

        if (!summaryByDays[timeLabel]) {
          summaryByDays[timeLabel] = {};
        }

        if (!summaryByDays[timeLabel][dayLabel]) {
          summaryByDays[timeLabel][dayLabel] = {
            maleTime: 0,
            femaleTime: 0,
            silenceTime: 0,
          };
        }

        const { totalSpeakTime, totalSilenceTime } =
          calculateTotalTimeIncludingSilence(job.results[0]);

        summaryByDays[timeLabel][dayLabel].silenceTime += totalSilenceTime;

        job.results[0].forEach((result) => {
          const duration = result.end - result.start;
          if (result.gender === "MALE") {
            summaryByDays[timeLabel][dayLabel].maleTime += duration;
          } else if (result.gender === "FEMALE") {
            summaryByDays[timeLabel][dayLabel].femaleTime += duration;
          }
        });
      });

      Object.keys(summaryByDays).forEach((timeLabel) => {
        const worksheet = XLSX.utils.json_to_sheet([]);
        XLSX.utils.sheet_add_aoa(worksheet, [[`Resum de ${timeLabel}`]]);

        let rowStart = 2;
        const sortedDays = Object.keys(summaryByDays[timeLabel]).sort(
          (a, b) => new Date(a) - new Date(b)
        );

        sortedDays.forEach((dayLabel) => {
          const { maleTime, femaleTime, silenceTime } =
            summaryByDays[timeLabel][dayLabel];
          //const totalJobTime = maleTime + femaleTime + silenceTime;
          const totalJobTime = maleTime + femaleTime;

          const malePercentage =
            totalJobTime > 0 ? (maleTime / totalJobTime) * 100 : 0;
          const femalePercentage =
            totalJobTime > 0 ? (femaleTime / totalJobTime) * 100 : 0;

          const dayData = [
            {
              Category: "Dones",
              Percentage: femalePercentage.toFixed(2) + "%",
            },
            { Category: "Homes", Percentage: malePercentage.toFixed(2) + "%" },
          ];

          XLSX.utils.sheet_add_aoa(
            worksheet,
            [
              [
                `Data: ${dayLabel}`,
                `Total de temps: ${formatTime(totalJobTime)}`,
              ],
              ["Category", "Percentage"],
            ],
            { origin: `A${rowStart}` }
          );

          XLSX.utils.sheet_add_json(worksheet, dayData, {
            origin: `A${rowStart + 3}`,
            skipHeader: true,
          });

          rowStart += 6;
        });

        XLSX.utils.book_append_sheet(
          workbook,
          worksheet,
          sanitizeSheetName(timeLabel)
        );
      });
    }

    if (workbook.SheetNames.length === 0) {
      console.error("No sheets were created");
      return;
    }

    XLSX.writeFile(
      workbook,
      `Stacked_Gender_Distribution_Including_TopSpeaker_${selectedFilter}.xlsx`
    );
  };

  //excel top speaker

  const handleExportExcludingTopSpeaker = async () => {
    const workbook = XLSX.utils.book_new();

    const filteredJobsForExport = filteredJobs.filter((job) => {
      const date = parse(job.date, "dd/MM/yyyy", new Date());
      const formattedDate = format(date, "dd/MM/yyyy");
      return selectedDates.some(
        (selectedDate) => selectedDate.value === formattedDate
      );
    });

    for (const job of filteredJobsForExport) {
      try {
        const allSpeakers = job.results[0].reduce((acc, result) => {
          const speaker = result.speaker;
          const timeSpoken = result.end - result.start;

          if (speaker && !isNaN(timeSpoken)) {
            acc[speaker] = (acc[speaker] || 0) + timeSpoken;
          } else {
            console.warn(
              `Datos incompletos para resultado en ${job.titol}`,
              result
            );
          }
          return acc;
        }, {});

        // Encontrar al top speaker general
        const topSpeaker = Object.entries(allSpeakers).reduce(
          (max, [speaker, time]) => (time > max.time ? { speaker, time } : max),
          { speaker: null, time: 0 }
        );

        // Determinar el género del top speaker
        const topSpeakerSegment = job.results[0].find(
          (segment) =>
            String(segment.speaker).trim() === String(topSpeaker.speaker).trim()
        );
        const topSpeakerGender = topSpeakerSegment
          ? topSpeakerSegment.gender || "Desconocido"
          : "Desconocido";

        // Filtrar segmentos excluyendo al top speaker
        const remainingSegments = job.results[0].filter(
          (segment) =>
            String(segment.speaker).trim() !== String(topSpeaker.speaker).trim()
        );

        // Acumular tiempos por género a partir de los segmentos restantes
        const genderTimes = remainingSegments.reduce(
          (acc, segment) => {
            const duration = segment.end - segment.start;
            if (segment.gender === "MALE") {
              acc.remainingMaleTime += duration;
            } else if (segment.gender === "FEMALE") {
              acc.remainingFemaleTime += duration;
            }
            return acc;
          },
          { remainingMaleTime: 0, remainingFemaleTime: 0 }
        );

        // Calcular el tiempo total restante
        const totalRemainingTime =
          genderTimes.remainingMaleTime + genderTimes.remainingFemaleTime;

        // Calcular porcentajes
        const recalculatedData = [
          {
            Category: "Dones",
            Percentage:
              totalRemainingTime > 0
                ? (
                    (genderTimes.remainingFemaleTime / totalRemainingTime) *
                    100
                  ).toFixed(2) + "%"
                : "0%",
          },
          {
            Category: "Homes",
            Percentage:
              totalRemainingTime > 0
                ? (
                    (genderTimes.remainingMaleTime / totalRemainingTime) *
                    100
                  ).toFixed(2) + "%"
                : "0%",
          },
        ];

        const worksheet = XLSX.utils.json_to_sheet([]);
        XLSX.utils.sheet_add_aoa(
          worksheet,
          [
            [`Programa: ${job.titol}`],
            [`Data: ${job.date}`],
            [`Top Speaker exclòs: ${topSpeakerGender} - ${topSpeaker.speaker}`],
            ["Category", "Percentage"],
          ],
          { origin: "A1" }
        );
        XLSX.utils.sheet_add_json(worksheet, recalculatedData, {
          origin: "A6",
          skipHeader: true,
        });

        XLSX.utils.book_append_sheet(
          workbook,
          worksheet,
          sanitizeSheetName(`${job.titol}`)
        );
      } catch (error) {
        console.error(
          `Error procesando programa ${job.titol}: ${error.message}`
        );
      }
    }

    if (workbook.SheetNames.length === 0) {
      console.error("No data to export");
      return;
    }

    XLSX.writeFile(
      workbook,
      "Stacked_Gender_Distribution_Excluding_TopSpeaker_ByDay.xlsx"
    );
  };

  const handleExportExcludingTopSpeakerByWeekOrMonth = async () => {
    const workbook = XLSX.utils.book_new();

    let filteredPrograms = [];
    if (selectedFilter === "SETMANA - DIES") {
      filteredPrograms = filterByWeekAndDays(filteredJobs, selectedDates);
    } else if (selectedFilter === "MES - DIES") {
      filteredPrograms = filterByMonthAndDays(filteredJobs, selectedDates);
    } else {
      filteredPrograms = filteredJobs.filter((job) => {
        const jobDate = parse(job.date, "dd/MM/yyyy", new Date());
        const weekNumber = getISOWeek(jobDate);
        const monthNumber = getMonth(jobDate) + 1;

        return selectedDates.some((date) => {
          if (selectedFilter === "SETMANA") {
            const weekFromSelection = parseInt(date.value.split(" ")[1]);
            return weekNumber === weekFromSelection;
          } else if (selectedFilter === "MES") {
            const monthFromSelection = parseInt(date.value.split(" ")[1]);
            return monthNumber === monthFromSelection;
          }
          return false;
        });
      });
    }

    if (filteredPrograms.length === 0) {
      console.error("No data to export");
      return;
    }

    let aggregatedData = {};

    filteredPrograms.forEach((job) => {
      const jobDate = parse(job.date, "dd/MM/yyyy", new Date());
      const timeLabel = selectedFilter.startsWith("SETMANA")
        ? `Setmana ${getISOWeek(jobDate)}`
        : `Mes ${getMonth(jobDate) + 1}`;
      const dayLabel = format(jobDate, "dd/MM/yyyy");

      if (
        selectedFilter === "SETMANA - DIES" ||
        selectedFilter === "MES - DIES"
      ) {
        if (!aggregatedData[timeLabel]) {
          aggregatedData[timeLabel] = { days: {}, programTitle: job.titol };
        }
        if (!aggregatedData[timeLabel].days[dayLabel]) {
          aggregatedData[timeLabel].days[dayLabel] = {
            maleTime: 0,
            femaleTime: 0,
            topSpeaker: null,
            silenceTime: 0,
          };
        }
      } else {
        if (!aggregatedData[timeLabel]) {
          aggregatedData[timeLabel] = {
            maleTime: 0,
            femaleTime: 0,
            programTitle: job.titol,
          };
        }
      }

      const maleResults = job.results[0].filter(
        (result) => result.gender === "MALE"
      );
      const femaleResults = job.results[0].filter(
        (result) => result.gender === "FEMALE"
      );

      const maleSpeakers = maleResults.reduce((acc, result) => {
        const speaker = result.speaker;
        const timeSpoken = result.end - result.start;
        acc[speaker] = (acc[speaker] || 0) + timeSpoken;
        return acc;
      }, {});

      const femaleSpeakers = femaleResults.reduce((acc, result) => {
        const speaker = result.speaker;
        const timeSpoken = result.end - result.start;
        acc[speaker] = (acc[speaker] || 0) + timeSpoken;
        return acc;
      }, {});

      const totalMaleTime = Object.values(maleSpeakers).reduce(
        (sum, time) => sum + time,
        0
      );
      const totalFemaleTime = Object.values(femaleSpeakers).reduce(
        (sum, time) => sum + time,
        0
      );

      const topMaleSpeaker = Object.entries(maleSpeakers).reduce(
        (max, [speaker, time]) => (time > max.time ? { speaker, time } : max),
        { speaker: null, time: 0 }
      );

      const topFemaleSpeaker = Object.entries(femaleSpeakers).reduce(
        (max, [speaker, time]) => (time > max.time ? { speaker, time } : max),
        { speaker: null, time: 0 }
      );

      const topSpeaker =
        topMaleSpeaker.time > topFemaleSpeaker.time
          ? { ...topMaleSpeaker, gender: "Homes" }
          : { ...topFemaleSpeaker, gender: "Dones" };

      const remainingMaleTime =
        totalMaleTime - (topSpeaker.gender === "Homes" ? topSpeaker.time : 0);
      const remainingFemaleTime =
        totalFemaleTime - (topSpeaker.gender === "Dones" ? topSpeaker.time : 0);

      if (
        selectedFilter === "SETMANA - DIES" ||
        selectedFilter === "MES - DIES"
      ) {
        aggregatedData[timeLabel].days[dayLabel].maleTime += remainingMaleTime;
        aggregatedData[timeLabel].days[dayLabel].femaleTime +=
          remainingFemaleTime;
        aggregatedData[timeLabel].days[dayLabel].topSpeaker = topSpeaker;
      } else {
        aggregatedData[timeLabel].maleTime += remainingMaleTime;
        aggregatedData[timeLabel].femaleTime += remainingFemaleTime;
      }
    });

    Object.keys(aggregatedData).forEach((timeLabel) => {
      const { maleTime, femaleTime, days, programTitle } =
        aggregatedData[timeLabel];

      if (selectedFilter === "SETMANA" || selectedFilter === "MES") {
        const totalRemainingTime = maleTime + femaleTime;

        const malePercentage =
          totalRemainingTime > 0 ? (maleTime / totalRemainingTime) * 100 : 0;
        const femalePercentage =
          totalRemainingTime > 0 ? (femaleTime / totalRemainingTime) * 100 : 0;

        const summaryData = [
          [`Programa: ${programTitle}`],
          [`Resum de ${timeLabel} (excloent Top Speaker)`],
          ["Category", "Percentage"],
          ["Dones", femalePercentage.toFixed(2) + "%"],
          ["Homes", malePercentage.toFixed(2) + "%"],
        ];

        const worksheet = XLSX.utils.aoa_to_sheet(summaryData);
        XLSX.utils.book_append_sheet(
          workbook,
          worksheet,
          sanitizeSheetName(timeLabel)
        );
      } else {
        Object.keys(days)
          .sort((a, b) => new Date(a) - new Date(b))
          .forEach((dayLabel) => {
            const {
              maleTime: dayMaleTime,
              femaleTime: dayFemaleTime,
              topSpeaker,
            } = days[dayLabel];

            const totalDayTime = dayMaleTime + dayFemaleTime;

            const malePercentage =
              totalDayTime > 0 ? (dayMaleTime / totalDayTime) * 100 : 0;
            const femalePercentage =
              totalDayTime > 0 ? (dayFemaleTime / totalDayTime) * 100 : 0;

            const dayData = [
              [`Programa: ${programTitle}`],
              [`Data: ${dayLabel}`],
              [`Top Speaker: ${topSpeaker.speaker} (${topSpeaker.gender})`],
              ["Category", "Percentage"],
              ["Dones", femalePercentage.toFixed(2) + "%"],
              ["Homes", malePercentage.toFixed(2) + "%"],
            ];

            const worksheet = XLSX.utils.aoa_to_sheet(dayData);
            const sheetName = sanitizeSheetName(`${timeLabel} - ${dayLabel}`);
            XLSX.utils.book_append_sheet(workbook, worksheet, sheetName);
          });
      }
    });

    if (workbook.SheetNames.length === 0) {
      console.error("No sheets were created");
      return;
    }

    XLSX.writeFile(
      workbook,
      `Stacked_Gender_Distribution_Excluding_TopSpeaker_${selectedFilter}.xlsx`
    );
  };

  return (
    <div id="chart" className="w-full max-w-screen-lg mx-auto">
      <div className="mb-4">
        <h1 className="text-xs text-black">
          Resultats acomulats per dia, setmana o mes
        </h1>
      </div>
      <div className="flex flex-row mb-4">
        <FilterSelect
          options={[
            { value: "day", label: "Dia" },
            { value: "SETMANA", label: "Setmana" },
            { value: "SETMANA - DIES", label: "Setmana - Dies" },
            { value: "MES", label: "Mes" },
            { value: "MES - DIES", label: "Mes - Dies" },
          ]}
          selectedValue={selectedFilter}
          onChange={(option) => handleFilterChange(option.value)}
        />
        {selectedFilter && selectedFilter !== "day" && (
          <div className="mb-4">
            <MultiSelect
              options={uniqueWeeksAndMonths}
              selectedValues={selectedDates}
              onChange={(selectedOptions) => {
                const updatedSelectedDates = Array.isArray(selectedOptions)
                  ? selectedOptions
                  : [selectedOptions];
                setSelectedDates(updatedSelectedDates);
              }}
              displayValue={(option) => option.label}
            />
          </div>
        )}
        {selectedFilter === "day" && (
          <div className="mb-4">
            <MultiSelect
              options={uniqueDays}
              selectedValues={selectedDates}
              onChange={(selectedOptions) => {
                const updatedSelectedDates = Array.isArray(selectedOptions)
                  ? selectedOptions
                  : [selectedOptions];
                setSelectedDates(updatedSelectedDates);
              }}
              displayValue={(option) => option.label}
            />
          </div>
        )}
      </div>

      <div className="flex flex-col justify-center space-y-6 md:space-y-8 lg:space-y-10">
        <div className="flex justify-center">
          <ReactApexChart
            options={chartOptions}
            series={chartSeries}
            type="bar"
            height={chartOptions.chart.height}
            width={chartOptions.chart.width}
          />
        </div>
      </div>
      <span className="text-xs text-black">
        Per descarregar els Excels, si us plau, primer selecciona les opcions
        que vols descarregar en els filtres d'aquest gràfic concret.
      </span>
      <div className="m-4 flex flex-row gap-4 items-start">
        <button
          onClick={
            selectedFilter === "day"
              ? handleExportByDay
              : handleExportByWeekOrMonth
          }
          className={`text-sm px-4 py-2 bg-gray-300 text-gray-800 rounded hover:bg-gray-400`}
        >
          {selectedFilter === "day"
            ? "Excel inclou speakers"
            : "Excel inclou speakers"}
        </button>
        <button
          onClick={
            selectedFilter === "day"
              ? handleExportExcludingTopSpeaker
              : handleExportExcludingTopSpeakerByWeekOrMonth
          }
          className="text-sm px-4 py-2 bg-gray-300 text-gray-800 rounded hover:bg-gray-400"
        >
          Excel exclou top speaker
        </button>
      </div>
    </div>
  );
};

export default StackedChart;
